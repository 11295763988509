Dashboard = Object.assign({ }, window.Dashboard || { }, {
    Sidebar: {
        init: function($, window, document) {
            var $sidebarMenu = $('.sidebar-menu');
            var $sidebarMenuLinks = $sidebarMenu.find(
                '.sidebar-menu__entry__link,' +
                '.sidebar-submenu__entry__link'
            );
            var $triggerLink = $sidebarMenu.find(
                '.sidebar-menu__entry--nested > .sidebar-menu__entry__link,' +
                '.sidebar-submenu__entry--nested > .sidebar-submenu__entry__link'
            );

            // Set Active Items
            (function() {
                var $sidebarMenuActiveLink = $sidebarMenuLinks
                    .filter(function() {
                        return window.location.href === $(this).prop('href');
                    })
                    .first();
                    
                function applyToParent($startElement) {
                    var $parent = $startElement.parents(
                        '.sidebar-submenu__entry--nested,' +
                        '.sidebar-menu__entry--nested'
                    );

                    if ($parent.length > 0) {
                        $parent.addClass('active open');
                        applyToParent($parent);
                    }
                }

                $sidebarMenuActiveLink.parent().addClass('active');
                applyToParent($sidebarMenuActiveLink);
            })();

            // Open / Close nested
            $triggerLink.on('click', function() {
                var $parent = $(this).parent(
                    '.sidebar-menu__entry--nested,' +
                    '.sidebar-submenu__entry--nested'
                );
                $parent.toggleClass('open');
            });
        }
    }
});
